<template>
  <div class="offer">
    <div class="info">
      <div>
        <div class="img">
          <img src="@/assets/images/hengxing/el2.gif" alt="">
        </div>
        <div class="title">
          无需注册，不记录隐私
        </div>
        <div class="desc">
          无需注册就可立即使用，我们十分注重隐私保护，通过多种加密算法，任何人都无法窥探您的访问记录。
        </div>
      </div>

    </div>
    <div class="info">
      <div>
        <div class="img">
          <img src="@/assets/images/hengxing/el1.gif" alt="">
        </div>
        <div class="title">
          智能带宽，晚高峰无忧
        </div>
        <div class="desc">
          特别为晚高峰设计的智能带宽调整算法，保证您在晚高峰都可以享受到4K秒开的流畅感受，告别一到晚上就卡顿的不良体验。
        </div>
      </div>

    </div>
    <div class="info">
      <div>
        <div class="img">
          <img src="@/assets/images/hengxing/el3.gif" alt="">
        </div>
        <div class="title">
          内置多个国家的线路选择
        </div>
        <div class="desc">
          每个国家又部署了不同的服务器，基于全球的服务器网络和独家传输协议，无限带宽，无任何网络节流。
        </div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: 'Offer',
  components: {},
  setup () {

  },
  created () {
  }
}
</script>

<style scoped>
div {
  text-align: center;
}

img {
  max-width: 250px;
}

.offer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 5%;
}

.title {
  font-size: 2rem;
  font-weight: 900;
  margin: 2rem 0;
}

.desc {
  color: #696969;
  padding: .5rem 2rem;
  font-size: 1.4rem;
}

.info {
  width: 80%;
  margin: 2rem 0;
}
.info img {
  padding: 1.5rem 5%;
  width: 70%;
}

@media all  and (min-width: 800px) {
  .offer {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 2rem;
  }
  .info {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  img {
    max-width: 345px;
  }

  .offer .info:first-child {
    margin-top: 2rem;
  }
}
</style>
