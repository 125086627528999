<template>
  <div className="download">
    <a @click="downloadInstall()" :href="`${apkUrl}`">

      <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAAAhFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD8qm6wAAAAK3RSTlMA9/uYIBoV8MW1iYMn5+Dz7daonhDp279/enBoHQLPpGGuj1kXC3U1MUQ8V4Q5QwAAAX1JREFUWMPt19luwjAQheGxQxaWhCWh7BC2Qnve//3aEiFHaMAnV1XVfHe+mJ8EWSNFVPPt4iyq6JCUwstgF4Uo1gEwE14OjLRnigID2+cabqB3luhSBkmWpllyOF4LifbAsFdwDfcKtl/uUtzZLC9zfOcjaeL24yZGnY0NbF5IQ5ctFLNB086ga6Ewk1OzTqcLnc1PzZ4Hz9hJKLzA4inT5zsfBi/EA/oSJbgRETju2CWvwGYVvw5Nj1zotMfLEH0rP0fwyK5UaAkfy73bDl4HKjSGB7vbhvB6o0LwS+U/Wr+Ps9kqIv8jPIw44cQAGAYhH3IjdQtT7ZoVHXIj6iXc8iE3UmdQGdIhN6JfQj6EuzbUhtpQG2pDvxOK+ZAbqRuhkvAhN1LXs/hhlnTIjTx8MxgAZhKSITeyC6Vu0+m/2em8I2yoGjFpNaKbwmssjAAe7PdxJ/A8U9oLhYc79diG2tBfD8XqkecWoHrkuQWoHAn6zlT2IUHZmcw+/AJQSZLPIj9eLAAAAABJRU5ErkJggg=="
          alt="">
      安卓下载
    </a>
  </div>
</template>

<script>
import utils from '../utils/utils'

export default {
  name: 'DownloadHengxing',
  components: {},
  setup() {
    let env = window.env
    let apkUrl = `/apks/${env.version}/${env.app}-${env.version}-release-${env.channel}.apk`

    function downloadInstall() {
      utils.statsDownload()
    }

    return {
      downloadInstall,
      apkUrl,
      env
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}

.download {
  font-weight: 900;
  font-size: 1.5rem;
  display: flex;
}

.download a {
  color: #fff;
  display: flex;

  max-width: 9rem;
  min-width: 5rem;
  /*border: 2px solid #000;*/
  border-radius: 25px;
  padding: 8px 1.5rem;
  background-color: #ff8000;
}

.download a img {
  width: 24px;
  heigh: 24px;
  margin-right: 8px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

@media all  and (min-width: 800px) {
  .download {
    justify-content: center;
  }

  .download a {
    justify-content: center;
    align-items: center;
  }
}

</style>
