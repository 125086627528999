<template>
  <div class="title">
    恒星加速能让您享受到哪些服务

  </div>
  <div style="height: 2rem;"></div>
  <div class="desc">
    恒星是市面上最稳定的加速器 <br>
    不卡顿不掉线，无论白天还是黑夜都一样快！
  </div>
</template>

<script>

  export default {
    name: 'WhyNeedHengxing',
    components: {},
  }
</script>

<style scoped>
div{
  padding: 0 5%;
  font-size: 30px;
}
  .title{
    text-align: center;
    font-weight: 900;
  }
  .desc{
    color: #696969;
    text-align: center;
    font-size: 20px;
  }
@media all  and (min-width: 800px) {
  .desc{
    font-size: 30px;
  }
}

</style>
