<template>
  <div className="banner">
    <div className="info">
      <div className="app-name">{{ appName }}</div>
      <div className="slogan" v-html="slogan">
      </div>
      <div style="height: 6rem;"></div>
      <Download></Download>
      <div style="height: 2rem;"></div>
    </div>
    <div className="poster" ref="poster">
      <img src="@/assets/images/hengxing/banner.gif" alt="">
    </div>
  </div>
</template>

<script>
import Download from "./DownloadHengxing"

export default {
  name: 'BannerHengxing',
  components: {
    Download
  },
  setup() {


    return {}
  },
  created() {
  }

}
</script>

<style scoped>

img {
  width: 64%;
}

* {

  z-index: 100;
}

.banner {
  color: #000000;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  background-image: url("~@/assets/images/hengxing/background-banner.jpg");
  overflow: hidden;
  color: #ffffff;
}

.app-name {
  font-size: 3.7rem;
  font-weight: 900;
  margin-top: 14px;
}

.slogan {
  margin-top: 2rem;
  text-align: start;
  font-size: 30px;
  line-height: 3.3rem;
  /*letter-spacing: 2px;*/
}

.record {
  text-align: right;
  padding: 1rem 10%;
  width: 80%;
}

.poster {
  width: 500px;
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(35px, 61px);
  overflow: hidden;
}

.link a {
  color: #ffff56db
}

@media all and (max-width: 800px) and (min-width: 150px) {
  .banner {
    min-height: 38rem;
    align-items: flex-start;
    background-size: auto 100%;
    overflow: hidden;
  }

  .banner .info {
    margin: 2rem 18px;
    width: -webkit-fill-available;
  }

  .slogan {

    font-size: 2rem;
    margin-top: 40px;
  }

  .poster {
    transform: translate(51px, 43px);
    bottom: 0;
    width: 400px;
    z-index: 10;
    position: absolute;
    right: 0;
    height: 100%;
  }

  .poster img {
    transform: translate(16px, 151px);
  }
}

@media all  and (min-width: 800px) {
  .banner {
    min-height: 34rem;
    background-size: 100%;
  }

  .app-name {
    font-size: 4.5rem;
  }

  .banner .info {
    margin: 3rem 0 0 0;
    transform: translate(-224px, 24px);
  }

  .slogan {
    letter-spacing: 1rem;
    /*text-indent: 1rem;*/
    line-height: 4rem;
    text-align: center;
    margin-bottom: 2rem;
  }
}
</style>
